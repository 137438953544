import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import AboutMe from '../components/AboutMe';
import Page from '../components/Page'
import { PortfolioSection } from "../components/portfolio/PortfolioSection";
import { sections } from '../data/portfolio'
import { Link } from 'react-router-dom'
import { light, primary, primaryDark } from '../styles/colors';
import { body } from '../styles/fonts';
import { breakpoints, fromDesktop, fromMedium, fromTablet, } from '../styles/mediaQueries';
import { PageTitle } from '../components/ui/PageTitle';
import { DNoise } from '../components/ui/ImageNoise';

interface RouteParams {
  section: string
}

const Grid = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr;
  padding: 2rem 1rem;
  grid-gap: 2rem;
  max-width: ${breakpoints.maxWidth}px;
  
  ${fromTablet} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
`

const ImageContainer = styled.div`
  overflow: hidden;
`

const SectionLink = styled(Link)`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  z-index: 10;
  height: 20rem;

  &::before {
    display: block;
    width: 100%;
    height: 100%;
    content: ' ';
    background-color: ${primary};
    transform: rotate(-2deg);
    position: absolute;
    top: 0;
    left: 0;
  }

  ${fromTablet} {
    height: auto;
  }

  img {
    display: flex;
    position: relative;
    max-width: 100%;
    transition: all 0.2s ease;
    filter: saturate(100%);
  }

  h2 {
    position: absolute;
    font-family: ${body};
    text-transform: uppercase;
    font-weight: 800;
    color: ${light};
    margin: 0;
    padding: 0.5em;
    bottom: 0;
    left: 0;
    background-color: rgba(21, 5, 120, 0.7);
    transition: all 0.3s ease;
    width: 100%;
    text-align: center;

    font-size: 2.5em;

    ${fromMedium} {
      font-size: 2em;
    }

    ${fromDesktop} {
      font-size: 2em;
    }
  }

  &:hover, &:focus {
    h2 {
      background-color: ${primaryDark};
    }

    img, &::after {
      filter: saturate(120%);
      transform: scale(1.1);
    }
  }
`;

export default function Portfolio() {
  const routeParams = useParams<RouteParams>();
  const hasSection = !!routeParams.section;

  return (
    <Page>

      {hasSection ? <PortfolioSection /> : (
        <>
          <PageTitle>Portfolio</PageTitle>

          <Grid>
            {sections.map(({ slug, name, thumbnail }) => (
                <SectionLink to={`/portfolio/section/${slug}`}>
                  <ImageContainer>
                    <DNoise>
                      <img src={thumbnail} alt={name} />
                    </DNoise>
                  </ImageContainer>
                  <h2>{name}</h2>
                </SectionLink>
            ))}
          </Grid>
        </>
      )}

      <AboutMe showTitle={false} />
    </Page>
  )
}
