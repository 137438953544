import { ReactNode } from "react";
import styled from "styled-components";
import { body } from "../../styles/fonts";
import { fromMedium } from "../../styles/mediaQueries";
interface Props {
  align?: 'center' | 'left';
  children: ReactNode | ReactNode[] | string | string[];
}

export const Container = styled.h1<{$align: string}>`
  font-family: ${body};
  text-transform: uppercase;
  text-align: center;
  font-size: 3rem;
  margin: 0;
  font-weight: 800;
  position: relative;
  z-index: 20;
  max-width: 100%;

  ${fromMedium} {
    text-align: ${props => props.$align};
  }

  & > .background-blob {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: transform 0.5s ease-in-out;
    transform-origin: center;
    transform: scaleY(2.5) scaleX(1.3);
  }
`

export function PageTitle ({children, align = 'center'}: Props) {
  return (
    <Container $align={align}>
      {children}
    </Container>
  )
}
