import {
  BrowserRouter as Router
} from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import styled from 'styled-components'

import {lg, sm} from './styles/spacing'
import { fromMedium } from './styles/mediaQueries'

import Routes from './Routes'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1300px;
  margin: auto;
  min-height: 100vh;
  padding: 0 ${sm}px;

  ${fromMedium} {
    padding: 0 ${lg}px;
  }
`

const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  main {
    flex: 1 1 auto;
    z-index: 100;
  }

  ${fromMedium} {
    flex-direction: row;
  }
`

const queryClient = new QueryClient()

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Router>
          <Layout>
            <ContentArea>
              <Routes />
            </ContentArea>
          </Layout>

          <div id="modal" />
        </Router>
        
      </QueryClientProvider>
    </div>
  );
}

export default App;
