const conventions = [
  {
    date: '19 October',
    location: 'Winchester',
    title: 'Winchester Comics Fair',
    link: 'https://www.instagram.com/winchcomics',
  },
  {
    date: '16-17 November',
    location: 'Harrogate',
    title: 'Thought Bubble Festival',
    link: 'https://www.thoughtbubblefestival.com/comicconvention',
  }
];

export default conventions;
