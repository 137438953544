import styled from "styled-components";
import TextItem from "./ui/TextItem";
import SocialLinks from "./SocialLinks";
import ContactForm from "./ContactForm";
import { fromSmall } from "../styles/mediaQueries";
import { light, primaryBackground } from "../styles/colors";

interface Props {
  showTitle?: boolean
  first?: boolean
  showIntro?: boolean
  showSocials?: boolean
  showContactInfo?: boolean
}

const ContactInfo = styled.section`
  width: 100vw;
  padding: 1rem;
  
  ${fromSmall} {
    width: 30rem;
  }
`

const Container = styled.div`
  background-color: ${primaryBackground};
  width: 100vw;
  margin: 3rem 0 1.5rem;
  padding: 0.2rem 0 0.5rem;

  h2 {
    color: ${light};
    margin: 0 0 0.2em;
    font-size: 2.2rem;
  }

  label {
    color: ${light};
  }
`;

export default function AboutMe ({first = false, showTitle = true, showIntro = true, showContactInfo = true, showSocials = false}: Readonly<Props>) {
  return (
    <Container>
      <TextItem first={first}>
        {/* {showTitle && <h3>A little about me</h3>} */}
        {/* {showIntro && <p>Claudia is a London-based illustrator and comic artist with a passion for <Emphasis>bold colours and science-fiction stories</Emphasis> (as you probably have noticed).</p>} */}

        {showContactInfo && <>
          <ContactInfo>
            <h2>Get in touch:</h2>
            <ContactForm />
          </ContactInfo>
        </>}

        {showSocials && <SocialLinks short />}
      </TextItem>
    </Container>
  )
}
