import styled, { css } from 'styled-components';
import gradient from '../../images/grainy-gradient.svg';

export const DNoiseCss = css`
  &::after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: repeat 0/0 url(${gradient});
    background-size: 349px 349px;
    opacity: 0.2;
    mix-blend-mode: hard-light;
    transition: all 0.3s ease;
  }
`

export const DNoise = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  ${DNoiseCss};
`;
