import 'photoswipe/dist/photoswipe.css'

import { ParsedQuery } from 'query-string'
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Gallery, Item } from 'react-photoswipe-gallery';
import { useImageSize } from 'react-image-size';
import Page from '../components/Page'
import { useQueryStringParams } from '../utils/queryParams';
import { PortfolioImage, projects } from '../data/portfolio';
import { breakpoints, fromMedium, fromSmall, fromTablet, toTablet } from '../styles/mediaQueries';
import { ChildrenFnProps } from 'react-photoswipe-gallery/dist/types';
import { light } from '../styles/colors';
import ProjectNavigation from '../components/ProjectNavigation';

interface QueryParams extends ParsedQuery<string | number | boolean> {
  image: string
}

interface RouteParams {
  project: string
}

const FolioDetails = styled.section`
  max-width: ${breakpoints.maxWidth}px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 2rem;
  position: relative;

  ${fromTablet} {
    flex-direction: row;
  }
`;

const MoreImages = styled.section`
  position: relative;
  display:flex;
  flex-direction: column;
  align-items: center;
  max-width: 76rem;
  margin: 1rem auto;
  font-size: 1.8rem;
  padding: 2rem 0;

  h3 {
    margin: 0 0 1em;
  }
`;

const Column = styled.div`
  flex: 1;
  padding: 1em;

  & > img {
    width: 100%;
    height: auto;
  }

  &:first-child {
    ${fromTablet} {
      max-width: 28rem;
    }
  }

  &:last-child {}
    max-width: 43rem;

    ${fromTablet} {
      width: 43rem;
    }
  }
`;

const MainImage = styled.div`
  cursor: pointer;
  position: relative;

  ${toTablet} {
    width: 100vw;
    max-height: 45vh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  & img {
    width: 100%;
  }
`;

const ImageThumbnail = styled.div<{$spanColumns?: number, $preventCropping?: boolean}>`
  cursor: pointer;
  position: relative;
  font-size: 0.8rem;
  grid-column: span ${props => props.$spanColumns || 1};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  img {
    width: 100%;

    ${props => !props.$preventCropping && `
      height: 100%;
      object-fit: cover;
    `}
  }
`;

const Images = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
  grid-gap: 1rem;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 100vw;
  padding: 1rem;
  grid-auto-flow: dense;

  ${fromSmall} {
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  }

  ${fromMedium} {
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  }
`;

const NavigationTop = styled.div`
  width: 100%;
  position: absolute;
  top: -3em;
  left: 0;
  z-index: 100;

  ${fromTablet} {
    display: none;
  }
`;

const NavigationMiddle = styled.div`
    width: 100%;
    display: block;
    padding: 0 1em;
`;

const ProjectInfo = styled.article`
  font-size: 1.3rem;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;

  ${fromTablet} {
    text-align: left;
  }

  ${toTablet} { 
    padding: 2em 1em 0.5em;
    position: relative;
  }
`;

const Title = styled.h2`
  font-size: 2em;
  margin-top: 0;
  margin-bottom: 0.1em;
`;

const Date = styled.p`
  margin-top: 0;
  opacity: 0.5;
  font-size: 1.2em;
`;

const Description = styled.div`
  line-height: 1.3;
`;

const Links = styled.ul`
  list-style: none;
  padding: 0 0 1em;
  margin-bottom: 0;

  li {
    margin: 0.2em 0;
  }

  li a {
    font-size: 1.1em;
  }
`;

const GalleryIcon = styled.i`
  position: absolute;
  top: 1em;
  right: 1em;
  font-size: 1.5em;
  color: ${light};
`;

function ImageGalleryItem({ image, children }: { image: PortfolioImage, children: (props: ChildrenFnProps<HTMLElement>) => JSX.Element }) {
  const [dimensions, { loading, error }] = useImageSize(image.imageUrl);

  if (loading) {
    return <div>{/* Loading... */}</div>;
  }

  if (error || !dimensions) {
    return <></>;
  }

  return (
    <Item
      original={image.imageUrl}
      thumbnail={image.imageThumbnail}
      caption={image.description || `Tags: ${image.type.join(', ')}`}
      key={image.slug}
      id={image.slug}
      width={dimensions?.width}
      height={dimensions?.height}
    >
      {children}
    </Item>
  );
}

export default function PortfolioProject() {
  const routeParams = useParams<RouteParams>()
  const urlParams = useQueryStringParams<QueryParams>()
  const currentProject = projects.find(project => project.slug === routeParams.project);
  const selectedImage = urlParams.image || currentProject?.images[0].slug;
  const imageData = currentProject?.images.find((image) => image.slug === selectedImage);

  if (!currentProject || !imageData) {
    return (
      <Page>
        {/* TODO: 404 page */}
        Project not found.
      </Page>
    )
  }

  return (
    <Page dynamicTitle={currentProject.title}>
      <Gallery id="portfolio-project-gallery" withCaption>
        <FolioDetails>
          <Column>
            <MainImage>
              <GalleryIcon className="fa-solid fa-images"></GalleryIcon>

              <ImageGalleryItem image={imageData}>
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={imageData?.imageUrl} alt={currentProject.title}  />
                )}
              </ImageGalleryItem>
            </MainImage>
          </Column>
        
          <Column>
            <ProjectInfo>
              <NavigationTop>
                <ProjectNavigation currentProject={currentProject} />
              </NavigationTop>

              <Title>{currentProject.title}</Title>
              <Date>{currentProject.date}</Date>

              <Description>
                {currentProject.description.map((paragraph) => (
                  <p key={paragraph}>{paragraph}</p>
                ))}
              </Description>

              {currentProject.externalLinks && (
                <Links>
                  {currentProject.externalLinks.map((link) => (
                    <li key={link.title}>
                      <a href={link.link} target='_blank' rel='noreferrer'><i className="fa-solid fa-link"></i> {link.title}</a>
                    </li>
                  ))}
                </Links>
              )}

              {currentProject.reviews && (
                <Images>
                  {currentProject.reviews.map((review) => (
                    <li key={review.author}>
                      <blockquote>
                        <p>{review.snippet}</p>
                        <footer>{review.author}</footer>
                      </blockquote>
                    </li>
                  ))}
                </Images>
              )}
            </ProjectInfo>
          </Column>
        </FolioDetails>

        <NavigationMiddle>
          <ProjectNavigation currentProject={currentProject} />
        </NavigationMiddle>

        {currentProject.images.length > 1 && (
          <>
            <MoreImages>
              <h3>More images</h3>
                <Images>
                    {currentProject.images.map((image) => image.slug !== selectedImage && (
                      <ImageGalleryItem image={image}>
                        {({ ref, open }) => (
                            <ImageThumbnail $spanColumns={image.spanColumns} $preventCropping={image.preventCropping}>
                              <GalleryIcon className="fa-solid fa-images"></GalleryIcon>

                              <img ref={ref} onClick={open} src={image.imageUrl} alt={currentProject.title} />
                            </ImageThumbnail>
                        )}
                      </ImageGalleryItem>
                    ))}
                </Images>
            </MoreImages>

            <ProjectNavigation currentProject={currentProject} />
          </>
        )}
      </Gallery>
    </Page>
  )
}
