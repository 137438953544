import styled from 'styled-components';
import { fromMedium } from '../styles/mediaQueries';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  margin: 1rem 0;
  font-size: 1.2rem;
  text-align: center;
  width: 100vw;
  padding: 1rem;
  
  ${fromMedium} {
    width: 30rem;
  }
`;

const Frame = styled.iframe`
  border: none;
  background: transparent;
  width: 100%;
  height: auto;
  width: 100%;
  margin: 0 auto;
`

export default function Newsletter () {
  return (
    <Container>
      <p><a href="https://claudiamatosa.com/newsletter" target="_blank" rel="noopener noreferrer">Subscribe to the newsletter</a> to follow work in progress, book releases and reviews:</p>

      <Frame
        title="substack"
        src="https://claudiamatosa.substack.com/embed"
        frameBorder="0"
        scrolling="no"
      ></Frame>
    </Container>
  )
}
