import LogoIcon from "./icons/Logo"
import styled from "styled-components"
import { NavLink, useRouteMatch } from "react-router-dom"
// import links from "../data/links"
import { breakpoints, fromDesktop, fromMedium, fromSmall, fromTablet, toSmall } from "../styles/mediaQueries"
import { body, emphasis } from "../styles/fonts"
import { primaryDark, secondaryDark } from "../styles/colors"
import SocialLinks from "./SocialLinks"
// import NavigationLink from "./NavigationLink"

const Navigation = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  max-width: ${breakpoints.maxWidth}px;
  gap: 1.5em;
  flex-wrap: wrap;
  margin: 1rem 0 2rem;
  font-size: 0.65rem;
  position: relative;
  z-index: 200;

  ${fromSmall} {
    flex-direction: row;
    gap: 0;
    margin: 0.5rem 0 1rem;
  }

  ${fromMedium} {
    font-size: 0.7rem;
    gap: 1em;
    margin: 0.5rem 0 0;
  }
`

const LogoContainer = styled.div`
  width: 5rem;
  height: auto;
  transition: all 0.5s ease-in-out;
  display: none;
  
  & > svg {
    width: 100%;
  }

  ${fromSmall} {
    width: 6rem;
  }

  ${fromMedium} {
    width: 5rem;
  }

  ${fromTablet} {
    width: 7rem;
  }

  ${fromSmall} {
    display: block;
  }
`

const Menu = styled.nav`
  font-family: ${body};
  font-size: 1.1rem;

  ul {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    list-style: none;
    padding: 0;
    margin: 0;

    ${fromTablet} {
      gap: 1em;
    }
  }

  a.active {
    color: ${secondaryDark};
  }
`

const MenuContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const Columns = styled.div`
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 0.5em;

  ${fromDesktop} {
    align-items: center;
    flex-direction: row;
    white-space: nowrap;
    gap: 5em;
  }

  &:first-child {
    text-align: left;
  }

  &:last-child {
    text-align: right;
  }
`;

const PageName = styled.h1`
  font-size: 1.2rem;
  font-family: ${emphasis};
  color: ${primaryDark};
  margin: 0;
  padding: 0;
`;

const SocialBar = styled.div`
  width: 100vw;

  ${fromSmall} {
    width: auto;
  }

  ${fromMedium} {
    font-size: 0.9em;
  }

  ${fromTablet} {
    font-size: 1em;
  }
`

function Header () {
  const {path} = useRouteMatch()
  const isHomepage = path === '/'

  return (
    <Navigation>
      <LogoContainer>
        <NavLink to="/"><LogoIcon title={isHomepage ? "Claudia Matosa Illustration" : "Back to the homepage"} /></NavLink>
      </LogoContainer>

      <Columns>
        <PageName><NavLink to="/" exact>Claudia Matosa Illustration</NavLink></PageName>

        <MenuContainer>
          <Menu>
            <ul>
              <li><NavLink to="/portfolio">Portfolio</NavLink></li>
              <li><NavLink to="/publications">Publications</NavLink></li>
              <li><NavLink to="/about">About</NavLink></li>
              {/* <li><NavLink to="/shop">Shop</NavLink></li> */}
              <li><a href="https://shop.claudiamatosa.com" target="_blank" rel="noreferrer">Shop</a></li>
              <li><NavLink to="/contact">Contact</NavLink></li>
            </ul>
          </Menu>
        </MenuContainer>
      </Columns>

      <SocialBar>
        <SocialLinks short marginTop={false} />
      </SocialBar>
    </Navigation>
  )
}

export default Header
