import styled from 'styled-components'
import { Gallery, Item } from 'react-photoswipe-gallery';
import { ChildrenFnProps } from 'react-photoswipe-gallery/dist/types';
import { useImageSize } from 'react-image-size';
import { PortfolioImage, PortfolioImageKeys, defaultPortfolioImages, getProject, getImageForProject } from '../../data/portfolio'
import { breakpoints, fromMedium, fromSmall } from '../../styles/mediaQueries';
import { light } from '../../styles/colors';
import { emphasis } from '../../styles/fonts';
import { DNoiseCss } from '../ui/ImageNoise';

interface Props {
  images?: PortfolioImageKeys;
}

const ProjectOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(22, 20, 59, 0.9);
  color: ${light};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-family: ${emphasis};
  opacity: 0;
  transition: opacity 0.3s;
  padding: 1em;
  text-align: center;
  font-weight: bold;
`;

/* https://www.smashingmagazine.com/2018/02/generated-content-grid-layout/ */
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  /* Doesn't work */
  grid-template-rows: repeat(auto-fill, 1fr);
  grid-gap: 1rem;
  justify-content: center;
  width: 100vw;
  max-width: ${breakpoints.maxWidth}px;
  margin: 0 auto;
  padding: 1em;
  flex-wrap: wrap;
  grid-auto-flow: dense;

  ${fromSmall} {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  ${fromMedium} {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
`

const GridItem = styled.a<{$spanColumns?: number, $preventCropping?: boolean}>`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  grid-column: span ${props => props.$spanColumns || 1};
  cursor: pointer;
  transition: all 0.3s ease;

  ${DNoiseCss};

  > img {
    width: 100%;
    height: 100%;
    object-fit: ${props => props.$preventCropping ? 'contain' : 'cover'};
  }

  &:hover, &:focus {
    filter: contrast(120%);
  }

  &:hover ${ProjectOverlay}, &:focus ${ProjectOverlay} {
    opacity: 1;
  }
`

function ImageGalleryItem({ image, children }: { image: PortfolioImage, children: (props: ChildrenFnProps<HTMLElement>) => JSX.Element }) {
  const [dimensions, { loading, error }] = useImageSize(image.imageUrl);

  if (loading) {
    return <div>{/* Loading... */}</div>;
  }

  if (error || !dimensions) {
    return <></>;
  }

  return (
    <Item
      original={image.imageUrl}
      thumbnail={image.imageThumbnail}
      // caption={image.description || `Tags: ${image.type.join(', ')}`}
      caption={image.description}
      key={image.slug}
      id={image.slug}
      width={dimensions?.width}
      height={dimensions?.height}
    >
      {children}
    </Item>
  );
}

// should receive filters as a prop
export function PortfolioGrid ({ images = defaultPortfolioImages }: Props) {
  return (
    <Gallery id="portfolio-gallery" withCaption>
      <GridContainer>
        {images.map(([project, image]) => {
          const imageData = getImageForProject(project, image);

          return imageData && !imageData.hideFromGallery ? (
            <ImageGalleryItem image={imageData}>
              {({ ref, open }) => (
                <GridItem ref={ref} onClick={open} $spanColumns={imageData.spanColumns} $preventCropping={imageData.preventCropping} key={image}>
                  <img src={imageData.imageMidSize} alt={getProject(project)?.title} />
                  {/* <ProjectOverlay>{getProject(project)?.title}</ProjectOverlay> */}
                </GridItem>
              )}
            </ImageGalleryItem>
          ) : null
        })}
      </GridContainer>
    </Gallery>
  )
}
