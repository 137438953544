import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Page from '../components/Page'
import { breakpoints, fromTablet, toTablet } from '../styles/mediaQueries';

import BioImage from '../images/bio-pics/claudiamatosa-photo.jpg';
import links from '../data/links';
import conventions from '../data/conventions';
import { PageTitle } from '../components/ui/PageTitle';
import AboutMe from '../components/AboutMe';
import services from '../data/services';

const FolioDetails = styled.section`
  max-width: ${breakpoints.maxWidth}px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 2rem;
  position: relative;
  
  ${fromTablet} {
    flex-direction: row;
    align-items: flex-start;
  }

  &:last-child {
    ${toTablet} {
      gap: 0;

      & > div:first-child {
        order: 2
      }

      & > div:last-child {
        order: 1
      }
    }
  }
`;

const Column = styled.div`
  flex: 1;
  padding: 1em 1em 0;

  ${fromTablet} {
    padding-bottom: 1em;
  }

  & > img {
    width: 100%;
    height: auto;
  }

  &:first-child {
    ${fromTablet} {
      padding-top: 1em;
      max-width: 22rem;
    }
  }

  &:last-child {
    max-width: 43rem;
    padding-top: 0;

    ${fromTablet} {
      padding-top: 0.5em;
    }
  }
`;

const MainImage = styled.figure`
  position: relative;
  margin: 0;
  max-width: 15em;
  filter: saturate(0.7) contrast(0.9);

  ${fromTablet} {
    max-width: 100%;
  }

  & img {
    width: 100%;
  }

  & figcaption {
    opacity: 0.6;
    text-align: center;
    padding-top: 0.5em;

    ${toTablet} {
      display: none;
    }
  }
`;

const Details = styled.article`
  font-size: 1.3rem;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;

  ${fromTablet} {
    text-align: left;
  }

  ${toTablet} { 
    padding: 0 1em 0.5em;
    position: relative;
  }
`;

const SmallTitle = styled.h3`
  font-size: 1.3em;
  margin-top: 0.5em;
  margin-bottom: 0;
`;

const Description = styled.div`
  line-height: 1.3;
`;

const Links = styled.ul`
  list-style: none;
  padding: 0 0 1em;
  margin-bottom: 0;

  li {
    margin: 0.2em 0;
  }
`;

const Services = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  font-weight: 500;

  li {
    margin: 0.2em 0;
  }
`;

const socialLinks = [
  {
    title: 'Newsletter',
    link: links.substack,
  },
  {
    title: 'LinkedIn',
    link: links.linkedIn,
  },
  {
    title: 'Instagram',
    link: links.instagram,
  },
  {
    title: 'Cara',
    link: links.cara,
  },
  {
    title: 'Twitter',
    link: links.twitter,
  },
  {
    title: 'Bluesky',
    link: links.bluesky,
  }
]

export default function About() {
  return (
    <Page>
        <FolioDetails>
          <Column>
            <MainImage>
                <img src={BioImage} alt="Claudia Matosa - Illustrator"  />
                {/* <figcaption>The most awkward photo she could find.</figcaption> */}
            </MainImage>
          </Column>
        
          <Column>
            <Details>
              <PageTitle align="left">About Me</PageTitle>
              {/* <Subtitle>If you're here for the answer, it's 42. 🐬</Subtitle> */}

              <Description>
              <p>I’m an <strong>illustrator</strong> based in London, with a passion for storytelling via colourful images… as you may have noticed.</p>

              <p>Alongside client work, I self-publish comic books and regularly participate in anthologies with other artists. This gives me in-depth knowledge of the process of drawing and designing for both print and digital media, as well as conceptualising worlds and characters for stories.</p>

              {/* <p>My studies include a degree in audiovisual and multimedia, a post-graduation in digital art, and short courses in storyboarding, background painting, children’s book illustration, animation, and creative writing.</p> */}

              <p>If my art style looks suitable for your project, <strong>I’d love to bring your ideas to life</strong>. Let’s <Link to="/contact">have a chat</Link>!</p>
              
                {/* <p>To date, she self-published six full-colour comics, participated in multiple anthologies with other artists (from WIP Comics and Distant Connections), and frequently attends fairs and conventions to find her crowd.</p> */}
              </Description>
            </Details>
          </Column>
        </FolioDetails>

        <FolioDetails>
          <Column>
            <Details>
              <SmallTitle>Next shows</SmallTitle>  
              <Links>
                {conventions.map((convention) => (
                  <li>
                    <a href={convention.link} target="_blank" rel="noreferrer">
                    <i className="fa-solid fa-comment" /> {convention.date}: {convention.title} ({convention.location})
                    </a>
                  </li>
                ))}
              </Links>

              <SmallTitle>Socials</SmallTitle>
              <Links>
                {socialLinks.map((link) => (
                  <li key={link.title}>
                    <a href={link.link} target='_blank' rel='noreferrer'><i className="fa-solid fa-link"></i> {link.title}</a>
                  </li>
                ))}
              </Links>
            </Details>
          </Column>

          <Column>
              <Details>

                <SmallTitle>Services</SmallTitle>

                <Services>
                  {services.map((service) => (<li>{service}</li>))}
                </Services>

                <Description>
                  <p>Please do feel free to reach out with queries related to any other fields: new ideas are always welcome!</p>
                </Description>
              </Details>
          </Column>
        </FolioDetails>

        <AboutMe />
    </Page>
  )
}
