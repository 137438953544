import { ReactNode } from "react"
import styled from "styled-components"
import { dark } from "../../styles/colors"
import { fromDesktop, fromXlarge } from "../../styles/mediaQueries"
interface Props {
  children: ReactNode | ReactNode[] | string | string[]
  first?: boolean
}

const Container = styled.article<{$first: boolean}>`
  position: relative;
  padding: ${props => props.$first? '0 1.5rem 1.5rem' : '1.5rem'};
  display: flex;
  gap: 1em;
  font-size: 1.3rem;
  flex-direction: column;
  color: ${dark};
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 100vw;

  ${fromDesktop} {
    padding: ${props => props.$first? '0 2rem 1rem' : '1rem 2rem'};
  }

  ${fromXlarge} {
    padding: ${props => props.$first? '0 3rem 2rem' : '2rem 3rem'};
    flex-direction: column;
  }
`

const Text = styled.div`
  flex: 0 0 auto;
  max-width: 26em;
`

export default function TextItem ({children, first = false}: Props) {
  return (
    <Container $first={first}>
      <Text>
        {children}
      </Text>
    </Container>
  )
}
