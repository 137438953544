import styled, { keyframes } from "styled-components";
import { Helmet } from "react-helmet";
import { useRouteMatch } from "react-router-dom";
import { findPage } from "../Routes";
import SocialLinks from "./SocialLinks";
import Header from "./Header";
import Newsletter from "./Newsletter";
import { primaryBackground } from "../styles/colors";

interface Props {
  children: React.ReactNode;
  dynamicTitle?: string;
}

const loadAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const Footer = styled.div`
  width: 100vw;
  text-align: center;
  background-color: ${primaryBackground};
  padding: 2rem 0;
  height: auto;
`;

const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem 0.5rem 0;
  width: 100vw;
  min-height: 100vh;
`

const Main = styled.main`
  opacity: 0;
  animation: ${loadAnimation} 0.8s ease-in-out forwards;
  animation-delay: 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100vw;
`

interface Props {
  children: React.ReactNode
}

export default function Page ({ children, dynamicTitle }: Props) {
  const {path} = useRouteMatch()
  const pageTitle = dynamicTitle ? `${dynamicTitle} | ${findPage(path)?.title}` : findPage(path)?.title
  const title = pageTitle ? `${pageTitle} | Claudia Matosa` : 'Claudia Matosa'

  return (
    <StyledPage>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Header />

      <Main>
        {children}
      </Main>

      <Newsletter />

      <Footer>
        <SocialLinks />
      </Footer>
    </StyledPage>
  )
}
