import styled from 'styled-components';
import Page from '../components/Page'
import { breakpoints, fromMedium, fromTablet, toTablet } from '../styles/mediaQueries';
import ContactForm from '../components/ContactForm';
import { PageTitle } from '../components/ui/PageTitle';
import services from '../data/services';

const FolioDetails = styled.section`
  max-width: ${breakpoints.maxWidth}px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 2rem;
  position: relative;
  
  ${fromTablet} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const Column = styled.div`
  flex: 1 1;
  padding: 1em 1em 0;
  width: 100vw;

  ${fromMedium} {
    width: 30em;
  }

  ${fromTablet} {
    padding-bottom: 1em;
  }

  & > img {
    width: 100%;
    height: auto;
  }

  &:first-child {
    ${fromTablet} {
      padding-top: 1em;
      width: 28rem;
    }
  }

  &:last-child {
    padding-top: 0;

    ${fromTablet} {
      padding-top: 0.5em;
    }
  }
`;

const Details = styled.article`
  font-size: 1.3rem;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;

  ${fromTablet} {
    text-align: left;
  }

  ${toTablet} { 
    padding: 0 1em 0.5em;
    position: relative;
  }
`;

const SmallTitle = styled.h3`
  font-size: 1.3em;
  margin-top: 0.5em;
  margin-bottom: 0;
`;

const Description = styled.div`
  line-height: 1.3;
  max-width: 30em;
  margin: 0 auto;
`;

const Services = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  font-weight: 500;

  li {
    margin: 0.2em 0;
  }
`;

export default function About() {
  return (
    <Page>
        <FolioDetails>
          <Column>
            <Details>
              <PageTitle align="left">Get In Touch</PageTitle>
              <ContactForm />
            </Details>
          </Column>

          <Column>
            <Details>
              <SmallTitle>Email</SmallTitle>

              <Services>
                <li>hello@claudiamatosa.com</li>
              </Services>

              <br />
            </Details>

            <Details>
              <SmallTitle>Services</SmallTitle>

              <Services>
                {services.map((service) => (<li>{service}</li>))}
              </Services>

              <Description>
                <p>Please feel free to reach out with queries related to any other fields. New ideas are always welcome!</p>
              </Description>
            </Details>
          </Column>
        </FolioDetails>
    </Page>
  )
}
