import { FormEvent, useState } from 'react';
import Botpoison from "@botpoison/browser";
import { useFormspark } from "@formspark/use-formspark";
import styled from 'styled-components';
import Button from './ui/Button';
import { primary, secondary } from '../styles/colors';

const botpoison = new Botpoison({
  publicKey: "pk_37556bcb-61bc-4e38-979d-ae51ab00bfa2"
});

interface FormState {
  name: string;
  email: string;
  phone?: string;
  message: string;
}

const FormContainer = styled.div`
  padding-top: 1em;
  display: block;
  width: 100%;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5em;

  label {
    display: flex;
    flex-direction: column;
    
    span {
      margin-bottom: 0.5em;
    }
  }

  input, textarea {
    padding: 0.5em;
    border: 1px solid ${primary};

    &:focus {
      border-color: ${secondary};
    }
  }

  p, span {
    line-height: 1.3;
  }
`;

export default function ContactForm() {
  const [submit] = useFormspark({
    formId: "SBkYzbuf9"
  });

  const [error, setError] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const [formState, setFormState] = useState<FormState>({
    name: '',
    email: '',
    message: '',
  });

  const submitForm = async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setError(false);
      setIsSubmitting(true);
      setSuccess(false);

      try {
        const { solution } = await botpoison.challenge();
        await submit({ ...formState, _botpoison: solution });
        setSuccess(true);
      } catch (e) {
        setError(true);
      }

      setIsSubmitting(false);
  };

  return (
    <FormContainer>
      <Form onSubmit={submitForm}>
        {/* <label>
          <span>What's your name?</span>

          <input
            type="text"
            value={formState.name}
            onChange={(e) => setFormState({...formState, name: e.target.value })}
            minLength={3}
            maxLength={200}
            required
            />
        </label> */}

        <label>
          <span>E-mail</span>

          <input
            type="email"
            value={formState.email}
            onChange={(e) => setFormState({...formState, email: e.target.value })}
            minLength={3}
            maxLength={200}
            required
            />
        </label>

        <label>
          <span>Phone number (optional)</span>

          <input
            type="tel"
            value={formState.phone}
            onChange={(e) => setFormState({...formState, phone: e.target.value })}
            minLength={5}
            maxLength={30}
            />
        </label>

        <label>
          <span>Message:</span>

          <textarea
            value={formState.message}
            onChange={(e) => setFormState({...formState, message: e.target.value })}
            maxLength={1000}
            rows={5}
            required
            />
        </label>

        {!success && (<span>
          <Button type="submit" disabled={isSubmitting}>{isSubmitting ? 'Sending…' : 'Send message'}</Button>
        </span>)}

        {success && <span>Thank you for reaching out! I'll get back to you in the next couple of days.</span>}
        
        {error && <p>Your message could not be sent, potentially due to a few cheeky robots roaming the internet. Please try again, or optionally please do reach out via email.</p>}
      </Form>
    </FormContainer>
  );
}
