const services = [
  'Book and Album Covers',
  'Comic Book Art',
  'Editorial Illustration',
  'Picture Books',
  'Storyboarding',
  'Visual Development',
  'Website Illustration'
];

export default services;
